import request from '@/utils/request'

export function queryPage(data) {
  return request({
    url: '/store/queryPage',
    method: 'post',
    data
  })
}

export function rankByMediaShowCount(data) {
  return request({
    url: '/store/rankByMediaShowCount',
    method: 'post',
    data
  })
}
