<template>
  <div class="page home">
    <div class="menu">
      <div class="menu-l">
        <span class="app-title">开图</span>
      </div>
      <div class="menu-r">
        <a class="about-us" target="_blank" href="/#/about">关于我们</a>
        <a class="about-us" target="_blank" href="https://lixiangkaitu.com/privacy/document/user_agreement.html">用户协议</a>
        <a class="about-us" target="_blank" href="https://lixiangkaitu.com/privacy/document/privacy.html">隐私政策</a>
      </div>
    </div>
    <header class="header">
      <div class="header-wrapper">
        <div class="header-con">
          <div class="header-con-l">
            <strong>推荐</strong>
            <input type="text">
          </div>
          <div class="header-con-r">
            <strong>排名</strong>
          </div>
        </div>
      </div>
    </header>
    <main class="main">
      <div class="main-l">
        <div class="media-show-card" v-for="item in mediaShowList" :key="item.id">
          <div class="media-show-card-t">
            <img :src="item.store.avatarUrl" alt="">
            <span class="store-name">{{ item.store.name }}</span>
          </div>
          <div class="media-show-card-c">
            <img :src="item.media.url" alt="">
          </div>
          <div class="media-show-card-b">
            <div v-if="item.user" class="user">
              <span>投稿来自：</span>
              <img :src="item.user.avatarUrl" alt="" class="user-avatar">
              <span class="user-name">{{ item.user.nickName }}</span>
            </div>
            <div class="btn-box">
              <i class="iconfont icon-heart-outline"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="main-r">
        <div class="tab-bar">
          <span :class="rankType == 0 ? 'selected' : ''" @click="rankType = 0">用户</span>
          <span :class="rankType == 1 ? 'selected' : ''" @click="rankType = 1">店家</span>
        </div>

        <div v-if="rankType == 0">
          <div class="rank-card-user" v-for="(item, index) in uesrList" :key="item.id">
            <span class="order-num" :class="index < 3 ? 'red' : ''">{{ index + 1 }}</span>
            <img :src="item.avatarUrl" alt="" class="user-avatar">
            <div class="user-info">
              <div class="user-name">{{ item.nickName }}</div>
              <div class="show-info">
                <span>在秀作品：</span>
                <span>{{ item.showCount }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="rankType == 1">
          <div class="rank-card-user" v-for="(item, index) in storeList" :key="item.id">
            <span class="order-num" :class="index < 3 ? 'red' : ''">{{ index + 1 }}</span>
            <img :src="item.avatarUrl" alt="" class="user-avatar">
            <div class="user-info">
              <div class="user-name">{{ item.name }}</div>
              <div class="show-info">
                <span>在秀作品：</span>
                <span>{{ item.showCount }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>

    <div class="copyright-box flex-1">
        <div class="copyright">&copy;2021</div>
        <div class="company">上海嘤其鸣网络科技有限公司</div>
        <div class="icp">&nbsp;沪ICP备 2021034677号-2</div>
      </div>
  </div>
</template>
<script>

import { queryPage, rankByMediaShowCount as rankUser } from "@/api/mediaShow";
import { rankByMediaShowCount as rankStore } from "@/api/store";

export default {
  name: "Home",
  data() {
    return {
      rankType: 0,
      mediaShowList: [],
      uesrList: [],
      storeList: [],
      page: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.getFeedList()
    this.rankUserReq()
    this.rankStoreReq()
  },
  methods: {
    async getFeedList() {
      const params = { pageNum: this.page.pageNum, pageSize: this.page.pageSize, state: 1 };
      const data = await queryPage(params);
      console.log(data)
      const { list } = data
      this.mediaShowList = list
    },
    async rankUserReq() {
      const params = { pageNum: this.page.pageNum, pageSize: this.page.pageSize };
      const data = await rankUser(params);
      console.log(data)
      const { list } = data
      this.uesrList = list
    },
    async rankStoreReq() {
      const params = { pageNum: this.page.pageNum, pageSize: this.page.pageSize };
      const data = await rankStore(params);
      console.log(data)
      const { list } = data
      this.storeList = list
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  overflow: hidden;
  .menu {
    width: 100%;
    display: flex;
    &-l {
      .app-title {
        font-weight: bold;
        line-height: 5rem;
        font-size: 1.8rem;
        margin-left: 2rem;
      }
    }
    &-r {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
    a {
      font-size: 1.4rem;
      height: 5rem;
      line-height: 5rem;
      text-decoration: none;
      cursor: pointer;
      z-index: 1000;
      margin: 0 1rem;
      color: #333;
    }
  }
  .header {
    position: relative;
    height: 5rem;
    width: 100%;
    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
    &-con {
      width: 800px;
      margin: 0 auto;
      display: flex;

      &-l {
        height: 100%;
        width: 500px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        strong {
          font-size: 1.6rem;
          margin-left: 0.8rem;
        }
        input {
          // margin-right: 0.8rem;
          border: none;
          height: 2.6rem;
          border-radius: 1.3rem;
          padding: 0 1.3rem;
          &:focus {
            border: none;
            outline: 0px;
          }
        }
      }
      &-r {
        height: 100%;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        strong {
          margin-left: 40px;
          font-size: 1.4rem;
        }
      }
    }
  }
  .main {
    flex: 1;
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin: 0 auto;
    // overflow-y: auto;
    overflow: hidden;
    &-l {
      width: 500px;
      box-sizing: border-box;
      overflow: auto;
      input {
        margin-bottom: 2rem;
      }
    }
    &-r {
      background-color: #fff;
      overflow: auto;
      padding-top: 1.6rem;
      width: 260px;
      box-sizing: border-box;
    }
  }
}
.media-show-card {
  padding: 0.8rem;
  background-color: #fff;
  margin-bottom: 1.6rem;
  &-t {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    img {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      overflow: hidden;
      background-color: #ededed;
    }
    .store-name {
      margin-left: 0.8rem;
    }
  }
  &-c {
    img {
      width: 100%;
      display: block;
    }
  }
  &-b {
    padding: .8rem;
    .user {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-name {
        margin-left: .8rem;
      }
      &-avatar {
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        overflow: hidden;
        background-color: #ededed;
      }
    }

    .btn-box {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.rank-card-user {
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  .user-avatar {
    width: 4rem;
    height: 4rem;
    border-radius: .6rem;
    overflow: hidden;
    background-color: #ededed;
  }
  .order-num {
    font-size: 1.6rem;
    font-style: italic;
    font-weight: 550;
    padding: 0 1.6rem;
    color: #999;
  }
  .user-info {
    margin-left: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .user-name {
    font-weight: 550;
  }
}
.rank-card-user .red {
  color: red;
}
.tab-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 1.6rem;
  span {
    margin: 0 .8rem;
    cursor: pointer;
    font-size: 1.3rem;
  }
}
.selected {
  font-weight: bold;
}
.copyright-box {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
@media screen and (max-width: 50rem) {

}
</style>
<style lang="scss">

</style>